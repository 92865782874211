import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const PricePage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="Prețuri" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <p>Ai mai jos lista completă de prețuri</p>
          <hr />

          <h2 id="table-scan">Pachete Dev + Scan + Print </h2>

          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Developare, scanare și print - color C41</td>
                <td>dimensiune 10x15</td>
                <td>53</td>
              </tr>
              <tr>
                <td>Developare, scanare și print - alb negru</td>
                <td>dimensiune 10x15</td>
                <td>58</td>
              </tr>
              <tr>
                <td>Developare, scanare și print - E6 Diapozitiv</td>
                <td>dimensiune 10x15</td>
                <td>90</td>
              </tr>
              <tr>
                <td>Developare, scanare și print - color ECN-2 </td>
                <td>dimensiune 10x15</td>
                <td>88</td>
              </tr>
            </tbody>
          </table>

          <hr />

          <h2 id="table-c41">Dev și scan C41 (negative color)</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dev color C41 + scanare standard</td>
                <td>35mm - film îngust</td>
                <td>31</td>
              </tr>
              <tr>
                <td>Dev color C41 + scanare standard</td>
                <td>120 - film lat</td>
                <td>49</td>
              </tr>
              <tr>
                <td>Dev color C41</td>
                <td>fara scanare</td>
                <td>22</td>
              </tr>
            </tbody>
          </table>

          <h2 id="table-bw">Dev și scan BW (negative alb-negru)</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Developare alb-negru + scanare standard</td>
                <td>35mm - film îngust</td>
                <td>36</td>
              </tr>
              <tr>
                <td>Developare alb-negru + scanare standard</td>
                <td>120 - film lat</td>
                <td>55</td>
              </tr>
              <tr>
                <td>Developare alb-negru</td>
                <td>fară scanare</td>
                <td>22</td>
              </tr>
            </tbody>
          </table>

          <h2 id="table-e6">Dev și scan E6 (pozitive color)</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dev E6 (diapozitive) + scan Standard</td>
                <td>35mm - film îngust</td>
                <td>66</td>
              </tr>
              <tr>
                <td>Dev E6 (diapozitive) + scan Standard</td>
                <td>120 - film lat</td>
                <td>69</td>
              </tr>
              <tr>
                <td>Developare E6 (diapozitive)</td>
                <td>fara scanare</td>
                <td>55</td>
              </tr>
            </tbody>
          </table>

     <h2 id="table-ecn2">Dev și scan ECN-2 (negative color, pelicula cinema)</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dev color ECN-2 + scanare standard</td>
                <td>35mm - film îngust</td>
                <td>66</td>
              </tr>
              <tr>
                <td>Dev color ECN-2</td>
                <td>fara scanare</td>
                <td>55</td>
              </tr>
            </tbody>
          </table>

          <h2 id="table-scan">Doar scanare</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Scanare STANDARD</td>
                <td>35mm - fară developare </td>
                <td>22</td>
              </tr>
              <tr>
                <td>Scanare STANDARD</td>
                <td>120 - fară developare </td>
                <td>33</td>
              </tr>
              <tr>
                <td>Scanare STANDARD</td>
                <td>110 - fară developare </td>
                <td>4/clișeu</td>
              </tr>
              <tr>
                <td>
                  Extra scan PREMIUM <sup>*</sup>
                </td>
                <td>se adaugă la scanarea STANDARD</td>
                <td>8</td>
              </tr>
              <tr>
                <td>
                  Extra scan MAX <sup>**</sup>
                </td>
                <td>se adaugă la scanarea STANDARD</td>
                <td>22</td>
              </tr>
            </tbody>
          </table>
          <span>
            <sup>*</sup>Format .tiff, calitate superioară, rezoluție 6
            MegaPixeli.
          </span>
          <br />
          <span>
            <sup>**</sup>Format .tiff, calitate superioară, rezolutie maximă
            aprox 30 MegaPixeli. Un film de 36 poziții poate avea peste 1.5GB.
          </span>

          <h2 id="print"> Print pe hârtie</h2>
          <table>
            <thead>
              <tr>
                <th>Dimensiuni</th>
                <th>Cantitate</th>
                <th>Preț (RON) / buc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2}>10x15</td>
                <td>între 1-100</td>
                <td>1.5</td>
              </tr>
              <tr>
                <td>peste 100</td>
                <td>1</td>
              </tr>

              <tr>
                <td rowSpan={2}>13x18</td>
                <td>între 1-100</td>
                <td>2.5</td>
              </tr>
              <tr>
                <td>peste 100</td>
                <td>2</td>
              </tr>

              <tr>
                <td rowSpan={2}>15x21</td>
                <td>între 1-100</td>
                <td>3</td>
              </tr>
              <tr>
                <td>peste 100</td>
                <td>2.5</td>
              </tr>

              <tr>
                <td>20x30</td>
                <td>-</td>
                <td>10</td>
              </tr>
            </tbody>
          </table>

          <h2 id="table">Alte servicii</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td>Foto ID</td>
                <td>regim normal</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Foto ID</td>
                <td>URGENȚĂ</td>
                <td>25</td>
              </tr> */}

              <tr>
                <td>Digitalizare video casetă</td>
                <td>per ora</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Digitalizare audio casetă</td>
                <td>per ora</td>
                <td>25</td>
              </tr>
            </tbody>
          </table>

          <h2 id="table">Workshopuri</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>All inclusive</td>
                <td>Fotografie, developare film, mărit pe hârtie</td>
                <td>490</td>
              </tr>
              <tr>
                <td>În curând</td>
                <td>alte workshopuri în curând</td>
                <td> -- </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <PricePage location={props.location} data={data} {...props} />
    )}
  />
);
